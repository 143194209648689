/* src/styles.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000000;
}

.Astronaut {
  height: 55vmin;
  pointer-events: none;
  position: absolute;
  left: 15px;
  z-index: 1;
}

.Moon {
  height: 70vmin;
  pointer-events: none;
  position: absolute;
  right: -15%;
  bottom: -15%;
  z-index: 2;
}
@media (prefers-reduced-motion: no-preference) {
  .Moon {
    -webkit-animation: rotating 720s linear infinite;
    -moz-animation: rotating 720s linear infinite;
    -ms-animation: rotating 720s linear infinite;
    -o-animation: rotating 720s linear infinite;
    animation: rotating 720s linear infinite;
  }
}

.App-header {
  background-color: #181a1f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: absolute;
}

.thought_body {
  display:flex;
  flex-flow:row wrap;
  justify-content:center;
  margin:0;
  align-items:center;
  align-content:center;
  width: 250px;
}
.thought {
  display:flex;
  background-color:#fff;
  padding:20px;
  border-radius:30px;
  min-width:40px;
  max-width:250px;
  min-height:40px;
  margin:20px;
  position:relative;
  align-items:center;
  justify-content:center;
  text-align:center;
}

.thought:before,
.thought:after {
  content:"";
  background-color:#fff;
  border-radius:50%;
  display:block;
  position:absolute;
  z-index:1;
}
.thought:before {
  width:44px;
  height:44px;
  top:-20px;
  left:28px;
  box-shadow:-50px 30px 0 -12px #fff;
}
.thought:after {
  bottom:-10px;
  right:26px;
  width:30px;
  height:30px;
  box-shadow:40px -34px 0 0 #fff,
             -28px -6px 0 -2px #fff,
             -24px 17px 0 -6px #fff,
             -5px 25px 0 -10px #fff;
  
}

.form-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.form-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  position: relative;
  width: 350px;
}

input[type="radio"] {
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.verified {
  position: absolute;
    left: 300px;
    z-index: 7;
    top: 170px;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.background-animation {
  position: absolute;
  top: 0;
  left: 250px;
  width: 100%;
  height: 100%;
  background-size: 400% 400%;
  animation: swing 5s infinite ease-in-out;
  z-index: 1;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}